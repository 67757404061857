import React, { useState } from "react"
import { useSpring, a } from "@react-spring/web"

import styles from "./styles.module.css"
import { getRndInteger, useInterval } from "./usefulScripts"

export default function Card({
    imageUrl,
    description,
    prop,
}: {
    imageUrl: string[]
    description: string
    prop: React.AllHTMLAttributes<HTMLElement>
}) {
    const { className, style } = prop
    const [onTouch, setOnTouch] = useState(false)
    const [onMouseEnter, setOnMouseEnter] = useState(false)
    const [flipped, set] = useState(false)
    const [resetCounter, setResetCounter] = useState(false)
    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 },
    })

    const imageClassNames =
        " \
          w-[90%] md:w-[50%] 2xl:w-[100%] \
          h-[80%] 2xs:h-[75%] xl:h-[95%]\
        "
    const delay = getRndInteger(4, 8) * 1000
    useInterval(() => {
        if (!onTouch && !resetCounter && !onMouseEnter) set((state) => !state)
    }, delay)

    return (
        <div
            className="flex justify-center pb-0 xl:pb-16 hover:cursor-pointer"
            onClick={() => {
                set((state) => !state)

                // Prevent immediate flip animation from useInterval
                setResetCounter(true)
                setTimeout(() => setResetCounter(false), delay)
            }}
            onMouseEnter={() => setOnMouseEnter(true)}
            onMouseLeave={() => setOnMouseEnter(false)}
            onTouchStart={() => setOnTouch(true)}
            onTouchEnd={() => {
                setOnTouch(false)

                // Prevent immediate flip animation from useInterval
                setResetCounter(true)
                setTimeout(() => setResetCounter(false), delay)
            }}
        >
            <a.div
                className={`${styles.c} ${className} flex flex-col justify-center items-center `}
                style={{ opacity: opacity.to((o) => 1 - o), transform }}
            >
                <div className={`w-100 flex flex-row justify-around ps-0 2xs:ps-3 md:ps-5 xl:mb-5`}>
                    <div className="font-serif text-base xs:text-lg md:text-2xl font-semibold">Before</div>
                    <div className="font-serif text-base xs:text-lg md:text-2xl font-semibold">After</div>
                </div>

                <div className={`h-100 w-100 flex flex-row justify-center items-center me-6 sm:me-12`} style={{ border: "0px solid green" }}>
                    <div
                        className={imageClassNames}
                        style={{ backgroundImage: `url(${imageUrl[0]})`, backgroundSize: "cover", border: "0px solid red" }}
                    ></div>
                    <div
                        className={imageClassNames}
                        style={{ backgroundImage: `url(${imageUrl[1]})`, backgroundSize: "cover", border: "0px solid red" }}
                    ></div>
                </div>
            </a.div>
            <a.div
                className={`flex flex-row justify-center items-center px-4 
                h-[10rem] 2xs:h-[20rem] sm:h-[20rem] md:h-[30rem]
                w-[90%]
                 `}
                style={{ opacity, transform, rotateX: "180deg", border: "0px solid green" }}
            >
                <div
                    className="text-justify align-middle  overflow-y-auto
                max-h-[90%]
                font-semibold
                text-xs sm:text-sm md:text-lg"
                    style={{ border: "0px solid red" }}
                >
                    {description}
                </div>
            </a.div>
        </div>
    )
}
