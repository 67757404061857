import { createBrowserRouter, RouterProvider } from "react-router-dom"

import App from "./App"
import ErrorPage from "./Error-page"
import About from "./About"
import Home from "./Home"
import Treatments from "./Treatments"
import Experience from "./Experience"
import ContactUs from "./Contact"
import TreatmentEntry from "./TreatmentEntry"
import { loader as TreatmentLoader } from "./TreatmentEntry"
const _router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: <Home />,
            },
            {
                path: "about",
                element: <About />,
            },
            {
                path: "treatments",
                children: [
                    {
                        index: true,
                        element: <Treatments />,
                    },
                    {
                        path: "/treatments/:treatmentId",
                        loader: TreatmentLoader,
                        element: <TreatmentEntry />,
                        errorElement: <ErrorPage />,
                    },
                ],
            },
            {
                path: "experience",
                element: <Experience />,
            },
            {
                path: "contact",
                element: <ContactUs />,
            },
        ],
    },
])
// console.log(_router.routes)
const Router = () => <RouterProvider router={_router} />

export default Router
