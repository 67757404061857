import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

import translationEN from "./locales/en/translation";
import translationZH from "./locales/zh/translation";


//Creating object with the variables of imported translation files
const resources = {
    en: {
        translation: translationEN,
    },
    zh: {
        translation: translationZH,
    },
};

//i18N Initialization
let usrlang = navigator.language || navigator.userLanguage;
let lang = "";
const pattern = /zh/g;

if (pattern.test(usrlang) === true) {
    lang = 'zh';
} else {
    lang = 'en';
}

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: lang, //default language
        //keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;