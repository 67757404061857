const translationEN = {
    nav: {
        Home: "Home",
        About: "About",
        Treatment: "Treatment",
        Specialty: "Specialty",
        Experience: "Experience",
        Contact: "Contact",

        Philosophy: "Philosophy",
        Product: "Services",
        Reviews: "Reviews",
        FAQ: "FAQ",
        PDF: "PDF",
    },

    home: {
        MainTitle: "Senior Spine Specialist",
        Name: "Master Chia",
        LastName: "Master Chia",
        Bullet1: "Senior specialist in treating spinal disorders and nervous systems issues",
        Bullet2: "Reduce pain and enhance recovery through orthopedic techniques",
        Bullet3: "Reconstructing joints, fascia, and spinal bones in various areas to improve body function",
        Button: "CONTACT",

        About: "About",
        Description1_1:
            "Master Chia is a senior spine specialist and established Efeng Spine Healing Center in 2015, carrying the legacy of health around the world",
        Description1_2: "",
        Description2: "To date, he has helped over 34,000 patients to achieve better health.",
        Quote: `"A fulfilling life starts with a healthy body."`,
        Description3:
            "Spine Healing treatment realigns misaligned bones and tissues to their original positions, helping patients regain health and vitality. Master Chia believes that when someone loses their health, it can put the entire family in distress. Similarly, when an someone regains their health, it brings happiness and harmony to the whole family. In reality, Master Chia is helping people to build a healthy future.",

        Qualification1: "Founded of Spine Healing Therapy in",
        Qualification2: "Senior Trainer in Skeletal Aesthetic in",
        Qualification3: "Senior Trainer in Advanced Musculoskeletal Trainer in",
        Qualification4: "Senior Mentor in Spinal Nerve Systems in",
        Qualification5: "Senior Trainer in Advanced Orthopedic Instructor in",
        Qualification6: "Invited as Consultant for Multiple Orthopedic Trauma Clinics in",

        Description4: "Master Chia's specialized in:",
        Skill1: "Treatment for slip discs",
        Skill2: "Treatment for hip and knee joints",
        Skill3: "Treatment for spinal scoliosis",
        Skill4: "Treatment for arthritis and rheumatism",
        Skill5: "Treatment for meniscus injuries of the knee",
        Skill6: "Readjustment treatment for O/X-shaped legs",
        Skill7: "Pelvic Readjustment",
        Skill8: "Facial skeletal aesthetic treatments",
        Skill9: "Frozen shoulder treatments",

        Description5:
            "Besides his clinical work, Master Chia is also a spiritual recovery consultant dedicated to contributing volunteer services to the community. He actively participates in various community service activities, sharing mental health knowledge and providing emotional support. Helping people improve their mood and mental state. Master Chia not only cares about the physical health of his patients but also their emotional and spiritual growth, bringing warmth and compassion to the community through his actions",
    },

    treatment: {
        Title: "Our Services",
        TreatmentDataKeys: {
            Orthopaedics: "Orthopaedics",
            "Orthopaedics and Joint": "Orthopaedics and Joint",
            neurology: "Neurology",
        },
        TreatmentData: {
            "Spinal-Nervous-System": {
                Title1: "Spinal Nervous System",
                Intro: "The spinal nervous system constitutes a vital component of the body's nervous system, comprising the spinal cord and the nerves originating from it. Essentially, it facilitates the transmission of information between the brain and the body.",
                Title2: "Numerous factors can contribute to issues with the spinal nervous system. Here are some prevalent causes:",
                Reasons: {
                    1: "Hereditary disease",
                    2: "Accidents",
                    3: "Slip Disc",
                    4: "",
                },
                Title3: "Degradation of the spinal nervous system can result in a cascade of severe repercussions:",
                Consequences: {
                    1: "Persistent or deteriorating pain: Prolonged nerve compression or damage can result in enduring, intense pain that may not respond to typical pain relief medications.",
                    2: "Compression of the spinal cord or spinal nerves can lead to movement disorders, resulting in muscle weakness, coordination loss, or paralysis, impacting activities like walking and object manipulation.",
                    3: "",
                    4: "",
                },
            },
            Scoliosis: {
                Title1: "Scoliosis",
                Intro: `Scoliosis, also referred to as scoliosis, is a lateral curvature of the spine from its usual straight alignment. Essentially, the spine loses its straightness and curves to one side, creating a "C" or "S" shape.`,
                Title2: "Numerous factors can contribute to scoliosis issues. Here are some prevalent causes:",
                Reasons: {
                    1: "Hereditary Scoliosis",
                    2: "Prolonged poor posture",
                    3: "Excessive use of one side of the body",
                    4: "",
                },
                Title3: "Exacerbation of scoliosis can result in several severe consequences:",
                Consequences: {
                    1: "Changes in appearance: A deteriorating curve could lead to alterations in your physical appearance, like uneven shoulders or hips, or a protruding chest.",
                    2: "Limitations in daily activities can impact the patient's quality of life due to pain, restricted mobility, and changes in body shape, potentially resulting in psychological issues like anxiety and depression.",
                    3: "",
                    4: "",
                },
            },
            Hunchback: {
                Title1: "Hunchback",
                Intro: `Kyphosis denotes an atypical curvature of the spine leading to the protrusion of the upper back (thoracic region), resulting in a distinct curve resembling a camel's back, hence the term "hunchback." This condition causes the upper body to seem inclined forward, with the shoulders drooping forward.`,
                Title2: "Numerous factors contribute to hunchback issues. Here are some prevalent causes:",
                Reasons: {
                    1: "Poor Posture",
                    2: "Congenital malformations involve abnormal spinal development at birth.",
                    3: "Prolonged poor sitting posture",
                    4: "",
                },
                Title3: "Degradation of the Hunchback can result in a cascade of severe repercussions:",
                Consequences: {
                    1: "Chronic back pain is often exacerbated as kyphosis progresses, causing an increase in the curvature of the back and potentially resulting in enduring back or low back pain.",
                    2: "Severe kyphosis can compress the chest cavity, limiting lung expansion and leading to breathing difficulties, chest tightness, or fatigue.",
                    3: "",
                    4: "",
                },
            },
            "Dowager-Hump": {
                Title1: "Dowager Hump",
                Intro: `The "Dowager Hump" moniker is commonly bestowed by the general public. Historically, it was often associated with affluent individuals. However, the "Dowager Hump" could also signify a critical issue. Beneath this favorable label lurks a potentially life-threatening health condition. The regions where this bag manifests typically involve the accumulation of fat or protrusions on the neck and shoulders.`,
                Title2: "Numerous factors contribute to the issue of Dowager Hump. Here are some prevalent causes:",
                Reasons: {
                    1: "Poor posture",
                    2: "Wrong posture",
                    3: "Spinal deformation",
                    4: "",
                },
                Title3: "The decline in fertility can result in a range of severe outcomes:",
                Consequences: {
                    1: "Cerebrovascular Strain: Severe compression of the cervical spine by the hump can impose added strain on the cerebral blood vessels, particularly in individuals with a history of cerebrovascular disease. This compression can impact blood flow, raising the likelihood of stroke or other cerebrovascular incidents.",
                    2: "In severe instances, apophysis may lead to fat buildup in the shoulders, compressing the chest cavity. This compression can impede lung expansion and potentially result in breathing challenges, particularly during physical exertion.",
                    3: "",
                    4: "",
                },
            },
            "Frozen-Shoulder": {
                Title1: "Frozen Shoulder",
                Intro: `Frozen shoulder refers to inflammation of the tissues surrounding the shoulder, resulting in pain, stiffness, and restricted movement. Essentially, it indicates an issue with the shoulder joint, muscles, and ligaments, leading to discomfort or reduced flexibility in shoulder movement.`,
                Title2: "Numerous factors can contribute to the occurrence of frozen shoulder. Here are some prevalent reasons:",
                Reasons: {
                    1: "Prolonged maintenance of a static posture",
                    2: "Repetitive shoulder motion",
                    3: "Acute shoulder injuries",
                    4: "",
                },
                Title3: "The deterioration of a frozen shoulder can result in a cascade of severe outcomes:",
                Consequences: {
                    1: "Nocturnal pain: Pain intensity may increase during the night, potentially disrupting sleep.",
                    2: "Progressing frozen shoulder can lead to adhesive capsulitis, resulting in severe stiffness and restricted shoulder movement.",
                    3: "Secondary shoulder injury may result from limited mobility, potentially causing overuse of adjacent joints or muscles and elevating the risk of harm.",
                    4: "",
                },
            },
            Kyphosis: {
                Title1: "Kyphosis",
                Intro: `"Kyphosis" is a posture where an individual leans forward with their head while standing or sitting, typically to improve visibility or correct posture. Essentially, it involves stretching the neck and tilting the head forward.`,
                Title2: "Numerous factors contribute to the occurrence of Kyphosis issues. Here are some prevalent reasons:",
                Reasons: {
                    1: "Poor habits that result in wrong posture",
                    2: "Neck pain or stiffness",
                    3: "Prolonged concentration or nervousness period",
                    4: "",
                },
                Title3: "Deterioration in neck examination can result in several severe consequences:",
                Consequences: {
                    1: "Stiffness: The neck may stiffen, restricting the typical range of head movement.",
                    2: "Prolonged neck posture may result in persistent pain in the neck muscles and ligaments.",
                    3: "Cervical spondylosis: Prolonged neck manipulation can hasten the progression of cervical spondylosis, resulting in its exacerbation and increased compression of the nerve roots.",
                    4: "",
                },
            },
            "Imbalance-Shoulder": {
                Title1: "Imbalance Shoulder",
                Intro: `"Imbalance Shoulder" denotes the variation in height between the two shoulders, where one is slightly elevated compared to the other when standing upright.`,
                Title2: "Numerous factors can contribute to the occurrence of imbalance shoulders. Here are some prevalent reasons:",
                Reasons: {
                    1: "Unilateral weight bearing.",
                    2: "Shoulder injuries.",
                    3: "Prolonged poor posture.",
                    4: "",
                },
                Title3: "The deterioration of imbalance shoulders can result in several severe consequences:",
                Consequences: {
                    1: "Reduced motor function: Inconsistent shoulder height can impact shoulder stability and strength, thereby influencing athletic performance.",
                    2: "Nerve issues: Chronic shoulder asymmetry may result in nerve compression, causing numbness, tingling, or weakness in the arms.",
                    3: "",
                    4: "",
                },
            },
            "Trigger-Finger": {
                Title1: "Trigger Finger",
                Intro: `"Trigger finger" refers to a cosmetic issue affecting the fingers, characterized by a bow-like appearance of the finger joints when extended, resembling a slingshot. Typically, this condition results from injury or deformity of the soft tissue in the finger joints.`,
                Title2: "Numerous factors contribute to the occurrence of trigger finger problems. Here are some prevalent reasons:",
                Reasons: {
                    1: "Congenital issues",
                    2: "Arthritis",
                    3: "Injury or damage",
                    4: "Tendon issues",
                },
                Title3: "Degradation of the Trigger-Finger can result in a cascade of severe repercussions:",
                Consequences: {
                    1: "Daily Challenges: Engaging in everyday activities like fastening clothes or utilizing tools may present increased difficulty.",
                    2: "Joint discomfort: Finger joints may experience stiffness or discomfort, particularly in the early morning or after prolonged inactivity.",
                    3: "Reduced hand function: Hand strength and dexterity may be impacted, leading to an overall decrease in hand function.",
                    4: "",
                },
            },
            "Bone-Spur": {
                Title1: "Bone Spur",
                Intro: `Bone spurs are additional bony growths that manifest as protrusions or projections on bone surfaces. These growths develop organically as bones adapt to prolonged friction, pressure, or injury in joints, the spine, or other areas of the body. They may exert pressure on neighboring tissues, leading to pain, inflammation, and restricted mobility.`,
                Title2: "Numerous factors contribute to the occurrence of bone spurs. Here are some prevalent reasons:",
                Reasons: {
                    1: "Long-term wear and tear on joints",
                    2: "Aging",
                    3: "Injury or overused",
                    4: "",
                },
                Title3: "A deteriorating bone spur can result in several severe outcomes:",
                Consequences: {
                    1: "Walking Difficulty: Bone spurs in the spine or heel can impact one's ability to walk, leading to pain or instability while walking.",
                    2: "Persistent pain: Bone spur growth can lead to enduring pain, particularly when pressure or movement is applied.",
                    3: "",
                    4: "",
                },
            },
            "Gelatinous-Knee": {
                Title1: "Gelatinous Knee",
                Intro: `Gelatinous Knee functions as a lubricant and cushion within the knee joint, primarily comprising collagen and other components. This substance aids in minimizing friction during knee movement, safeguarding bones and joints. Essentially, it serves as a lubricant within the knee, facilitating smoother knee operation.`,
                Title2: "Numerous factors contribute to the occurrence of knee problems. Here are some prevalent reasons:",
                Reasons: {
                    1: "Aging",
                    2: "Arthritis",
                    3: "Overused",
                    4: "",
                },
                Title3: "Exacerbation of gelatinous knee can result in various serious consequences:",
                Consequences: {
                    1: "Colloidal deterioration can result in inadequate knee joint lubrication and heightened friction, leading to enduring pain and discomfort.",
                    2: "Restricted mobility: Pain and stiffness can diminish the knee's range of motion, impacting daily activities like walking, stair climbing, or running.",
                    3: "",
                    4: "",
                },
            },
            "Pelvic-Readjustment": {
                Title1: "Pelvic Readjustment",
                Intro: `Pelvic repair involves aiding the pelvis in returning to its natural state. This encompasses addressing pelvic fractures, rectifying pelvic tilt, or pelvic reduction. The objective of this procedure is to reestablish the pelvis to its standard position and functionality, alleviate discomfort, and enhance mobility.`,
                Title2: "Numerous factors can contribute to pelvic issues. Here are some prevalent causes:",
                Reasons: {
                    1: "Maternity",
                    2: "Incorrect posture",
                    3: "Obesity",
                    4: "",
                },
                Title3: "Pelvic degeneration can result in various severe outcomes:",
                Consequences: {
                    1: "Pelvic issues can impact the placement and operation of the bladder and reproductive organs, resulting in urinary incontinence, irregular menstruation, or other associated symptoms.",
                    2: "Joint issues: Prolonged pelvic degeneration can lead to added pressure on adjacent joints like the hips and knees, heightening the likelihood of joint discomfort or damage.",
                    3: "",
                    4: "",
                },
            },
            "Sciatic-nerve": {
                Title1: "Sciatic nerve",
                Intro: `The sciatic nerve, the longest nerve in the body, extends from the lower back to the legs, transmitting sensations and controlling leg muscles. Essentially, it serves as the conduit for communication between the brain and legs, overseeing leg movements and sensations.`,
                Title2: "Numerous factors can contribute to issues with your sciatic nerve. Here are some prevalent reasons:",
                Reasons: {
                    1: "Prolonged poor posture",
                    2: "Spinal stenosis occurs when the space within the spine narrows",
                    3: "Pelvic misalignment or deformation.",
                    4: "",
                },
                Title3: "Degradation of the sciatic nerve can result in various severe outcomes:",
                Consequences: {
                    1: "Severe pain in the leg typically originates in the lower back, radiating down the back or outer side of the leg, and can be intense and piercing.",
                    2: "Numbness or tingling in the legs: Issues with the sciatic nerve can lead to unusual sensations in the legs, like numbness, tingling, or pins and needles.",
                    3: "",
                    4: "",
                },
            },
            "Ear-fluid-imbalance": {
                Title1: "Ear fluid imbalance",
                Intro: `Fluid imbalance is a condition involving the equilibrium of fluid within the inner ear, crucial for regulating the body's balance and head positioning. This fluid operates in the cochlea and the vestibule, affecting sensations of dizziness, loss of balance, or vertigo when its levels are imbalanced.`,
                Title2: "Numerous factors can contribute to ear fluid imbalance. Here are some prevalent causes:",
                Reasons: {
                    1: "Pressure fluctuations in the ear",
                    2: "Side effects from Medication",
                    3: "Compressed C2 cervical vertebra",
                    4: "",
                },
                Title3: "If the imbalance in the ear fluid worsens, it may result in several serious consequences:",
                Consequences: {
                    1: "Emotional distress: Prolonged dizziness and discomfort can lead to emotional issues like anxiety and depression, impacting mental well-being.",
                    2: "Severe vertigo is characterized by a sensation of spinning, affecting either oneself or the surrounding environment, which can impede standing or walking.",
                    3: "Hearing Impairment: Deterioration of ear fluid balance can impact hearing, potentially resulting in hearing loss.",
                    4: "",
                },
            },
            "Lumbar-vertebrae-from-L1-to-L5": {
                Title1: "Lumbar vertebrae L1 - L5",
                Intro: `The lumbar vertebrae constitute the lower back vertebrae, pivotal for supporting the upper body's weight and enabling various movements. Maintaining the health of these vertebrae is crucial for proper posture and motor function. They are categorized as L1 - L5. L1 - L5。`,
                Title2: "Numerous factors contribute to lumbar spine issues. Here are some prevalent causes:",
                Reasons: {
                    1: "Prolonged sitting or poor posture",
                    2: "Lifting heavy objects or sudden twisting of the waist",
                    3: "Slip Disc",
                    4: "",
                },
                Title3: "Degradation of the lumbar spine can result in various severe outcomes:",
                Consequences: {
                    1: "Restricted mobility: The flexibility of the waist is diminished, making actions like bending, twisting, and lifting legs challenging.",
                    2: "Severe lumbar deterioration can lead to scoliosis or other deformities, impacting the body's stability and function.",
                    3: "",
                    4: "",
                },
            },
            "Thoracic-vertebrae-T1-T12": {
                Title1: "Thoracic vertebrae T1 - T12",
                Intro: `The thoracic vertebrae constitute the spinal column's midsection, supporting the upper body's weight, safeguarding the spinal cord, and facilitating bending and twisting movements. Additionally, they articulate with the ribs, contributing to the structure of the chest cavity.`,
                Title2: "Numerous factors contribute to the occurrence of thoracic spine issues. Here are some prevalent causes:",
                Reasons: {
                    1: "Injury",
                    2: "Slip Disc",
                    3: "Scoliosis",
                    4: "",
                },
                Title3: "Deterioration of the thoracic spine can result in several severe consequences:",
                Consequences: {
                    1: "Nerve issues: Compression or injury to the thoracic spine can impact the nerves, leading to numbness, tingling, or weakness in the arms or upper body.",
                    2: "Breathing Difficulty: Issues with the thoracic spine can impact the regular expansion of the chest cavity, leading to breathlessness or chest pain.",
                    3: "",
                    4: "",
                },
            },
            "Cervical-vertebrae-C1-C7": {
                Title1: "Cervical vertebrae C1 - C7",
                Intro: `The cervical vertebrae are situated in the neck, connecting the head to the upper body. Their primary functions include supporting the head's weight, enabling neck movement, and safeguarding the spinal cord's position in the neck. In essence, the cervical vertebrae facilitate neck mobility while maintaining spinal stability.`,
                Title2: "Numerous factors contribute to cervical spine issues. Here are some prevalent causes:",
                Reasons: {
                    1: "Neck injuries",
                    2: "Poor posture",
                    3: "Muscle tension",
                    4: "",
                },
                Title3: "Degeneration of the cervical spine can result in a cascade of severe outcomes:",
                Consequences: {
                    1: "Chronic neck pain: The pain can worsen and spread to the shoulders, upper back, or head.",
                    2: "Severe cervical spine issues can impact blood flow or nerve function, leading to dizziness or balance issues.",
                    3: "Arm and hand symptoms: Issues with the cervical spine can lead to nerve compression, resulting in numbness, tingling, or weakness in the arms or hands.",
                    4: "",
                },
            },
            "Feet-with-OX-deformity": {
                Title1: "Feet with O/X deformity",
                Intro: `Feet with an X-shape (also known as pigeon-toed feet) are characterized by the inside of the knees touching or being close together when standing upright, with the outside of the ankles further apart. Conversely, O-shaped feet (also referred to as pigeon-toed feet) exhibit a distinct gap between the outside of the knees while the inside of the ankles are closer together when standing upright. In this case, the knees bend outward while the ankles are closer together.`,
                Title2: "Numerous factors contribute to the occurrence of O/X-shaped feet. Here are some prevalent reasons:",
                Reasons: {
                    1: "Extended work hours",
                    2: "Developmental issues",
                    3: "Prolonged standing or poor gait",
                    4: "",
                },
                Title3: "The deterioration of O/X foot type could result in a range of severe consequences:",
                Consequences: {
                    1: "Knee Pain: Pain and discomfort in the knee may result from heightened pressure on the inner or outer part of the knee joint.",
                    2: "Walking Difficulty: An irregular gait can lead to instability or discomfort while walking.",
                    3: "Postural issues can result in suboptimal overall posture, impacting the alignment of the spine and other joints.",
                    4: "",
                },
            },
            Paralysis: {
                Title1: "Paralysis",
                Intro: `Paralysis refers to the loss of sensation or mobility in a specific body part. This condition may manifest as an inability to move or a lack of tactile sensation in the affected area. For instance, a compressed nerve can lead to numbness in the hand, or weakness and immobility in the arm. Paralysis can affect any part of the body and is commonly the result of nerve compression, injury, or disease.`,
                Title2: "Numerous factors can contribute to the occurrence of paralysis issues. Here are some prevalent causes:",
                Reasons: {
                    1: "Nerve compression",
                    2: "Nerve damage",
                    3: "Stroke",
                    4: "Slip Disc",
                },
                Title3: "Paralysis can result in a cascade of severe repercussions:",
                Consequences: {
                    1: "Muscle Atrophy: Prolonged paralysis can lead to muscle atrophy, resulting in weakened muscles due to lack of regular use and exercise.",
                    2: "Heightened sensory loss: The paralyzed region might experience further loss of sensation, resulting in a complete inability to perceive touch, temperature, or pain.",
                    3: "",
                    4: "",
                },
            },
            Headache: {
                Title1: "Headache",
                Intro: `A headache is a sensation of discomfort or pain experienced in the head. It can vary in nature, ranging from dull to stabbing or pulsating, typically occurring anywhere within the head.`,
                Title2: "Numerous factors contribute to the occurrence of headaches. Here are some prevalent causes:",
                Reasons: {
                    1: "Neck issues",
                    2: "Compression of the C1 cervical vertebra",
                    3: "",
                    4: "",
                },
                Title3: "Exacerbation of headaches can result in various severe outcomes:",
                Consequences: {
                    1: "Functional limitations: Severe headaches can impede the performance of daily tasks like work, school, or social activities.",
                    2: "Vision issues: Intense headaches, particularly migraines, can impact vision, leading to blurred vision or visual disruptions.",
                    3: "",
                    4: "",
                },
            },
            Migraine: {
                Title1: "Migraine",
                Intro: `A migraine is a prevalent form of headache characterized by intense, pulsating pain on one side of the head. It may persist for hours to days and is frequently accompanied by symptoms like nausea, vomiting, and sensitivity to light or sound.`,
                Title2: "Numerous factors contribute to the occurrence of migraines. Here are some prevalent ones:",
                Reasons: {
                    1: "Neck and shoulder issues",
                    2: "Degenerative conditions of the cervical spine",
                    3: "Compression of the C1 cervical vertebra",
                    4: "",
                },
                Title3: "Exacerbation of a migraine can lead to several severe outcomes:",
                Consequences: {
                    1: "Functional limitations: Severe headaches can impede the performance of daily tasks like work, school, or social activities.",
                    2: "Vision issues: Intense headaches, particularly migraines, can impact eyesight, leading to blurred vision or visual disruptions.",
                    3: "",
                    4: "",
                },
            },
        },
    },

    about: {
        Title: "About Efeng Spine Healing",
        Para1: "Master Chia, the founder of Efeng Spine Healing and formerly known as Chia Wei Long, hails from Seri Kembangan, Selangor, Malaysia. He has gained recognition as a distinguished Chinese medicine practitioner in China, Singapore, and Malaysia. Throughout his upbringing, Master Chia consistently observed the suffering inflicted by illness upon those around him. This led him to understand that when a family member's health deteriorates, the entire family faces hardships.",
        Para2: "Seeking to become a physician and provide healthcare to all individuals, he made a heartfelt wish. However, financial hardships confronted his family, making it difficult to afford his medical education abroad. Determined, he worked tirelessly and saved money until he had enough to pursue his studies overseas. There, he delved into various disciplines, including chiropractic, massage, acupuncture, and facial rejuvenation techniques. With his medical license in hand, he commenced treating patients, experiencing immense satisfaction in healing many. However, as his patient count grew, he encountered complex and baffling cases. Among a hundred patients, only seventy would recover, leaving him disheartened by the remaining thirty who deteriorated without improvement. Despite feeling powerless, his determination remained unwavering as he wished for their recovery and good health.",
        Para3_title: "Background",
        Para3_1: {
            zh: "",
            en_p1: "However, Master Chia remained resolute and unwavering. He firmly held the belief that within this expansive world, there had to be a treatment that could guarantee the recovery and well-being of every individual. Driven by this conviction, he devoted all his savings to pursue further studies abroad. Even if it meant exerting all his efforts to heal just one patient, he was willing to do so. However, during that period, he faced the daunting challenge of exorbitant tuition fees, which plunged him into financial hardship. He could only sustain himself on meager meals of bread and instant noodles. Upon learning of his decision to pursue overseas education, his family expressed opposition due to financial concerns. Confronted with financial constraints and familial resistance, he also encountered courses that charged exorbitant fees without providing substantial knowledge. Enduring the torment of managing living expenses, working during the day, providing treatments at night to earn a living, and utilizing every spare moment for studying, his life revolved around a relentless cycle of work, meals, treatments, and studying.",
            en_p2: "In spite of that, fortune smiled upon him, perhaps guided by benevolence. He gradually crossed paths with exceptional mentors and encountered renowned doctors. Master Chia expressed profound gratitude towards these guiding figures. With the accumulation of extensive clinical experience and the guidance of these esteemed physicians, he made a groundbreaking discovery. He realized that each person's body follows a distinct pattern, and by restoring the alignment of their muscles and bones to their original positions, significant improvements could be achieved in alleviating acidity, pain, and numbness. This approach even extended to internal medicine conditions such as ",
        },
        Para3_2: "migraines",
        Para3_3: "insomnia",
        Para3_4: "dizziness",
        Para3_5: "gastric issues",
        Para3_6: "chest tightness",
        Para3_7:
            ", and more, all of which showed potential for recovery. It was truly remarkable. Finally, after relentless dedication and positively impacting the lives of numerous individuals, Master Chia established the ",
        Para3_8: "Efeng Spine Healing",
        Para3_9: " therapy in 2015, drawing upon his profound insights and experiences.",
        Para3_10: "Through a series of accomplishments and experiences, Master Chia's ",
        Para3_11: "Efeng Spine Healing",
        Para3_12: " therapy has successfully aided over",
        Para3_13: " 34,000",
        Para3_14:
            " patients in achieving optimal health. Moreover, it has played a pivotal role in fostering harmony within numerous families. Just as the loss of one's health can plunge an entire family into distress, the restoration of good health can bring about happiness and serenity to the entire household. Looking ahead, an even greater number of individuals will undoubtedly reap the benefits of this therapy.",
    },

    experience: {
        Title1: "Experience",
        Title2: "Cases",
        Case: {
            1: "Before the treatment, my right arm had bone misalignment, leading to a partial dislocation. My upper body fascia was tight and wrinkled, my right scapula was painful, my right thumb lacked strength when playing the piano, and I couldn't lift my right arm high. After Master Chia treatment, my right arm's bone has been realigned, and my arm movements are now more fluid and flexible. In particular, through spine healing treatment, my right arm went from being unable to lift high to a full recovery. This treatment not only alleviated my pain but also restored my normal range of motion, allowing me to regain confidence in my life.",
            2: "Previously, my arm was very painful from an injury while drawing, and even painkillers did not help. However, after treatment, I finally feel my arm has fully recovered and I am now able to create freely",
            3: "When I came here, I finally found a solution to my problem of not being able to lift my hand high! Because I often fell, my whole body became tense and uncomfortable, and I felt pain along my left side from my waist to my foot. Once, when I fell, I used my left elbow to support myself, and as a result, my left arm became so painful that I couldn’t lift it. My parents thought the situation was serious because I couldn't raise my arm. Fortunately, when I came to Efeng Spine Healing Center to meet Master Chia, we identified the cause of the problem. After the treatment, I was finally able to lift my arm completely.",
            4: "My whole body feels very comfortable and light after the treatment.",
            5: "My hand lacked strength, and even lifting a cup of water made me shake with effort. My whole body felt tense and painful, and my legs were weak and numb, with a cold sensation. Even wearing two pairs of socks every day didn’t help, and my feet still felt very cold. I’ve been dealing with this condition for many years. Through the introduction of a friend, I came across Musculoskeletal Rehabilitation. After treatment by Master Chia, my hand, which I previously couldn’t lift, can now be raised freely. Currently, the coldness and numbness in my feet have also improved by 90%.",
            6: "I have been dealing with back pain for 3 years. I had difficulty sleeping at night due to the intense pain, even feeling nauseous and wanting to vomit, which made it impossible for me to be at ease. After undergoing spinal and muscle rehabilitation at Efeng Spine Healing Center, I finally discovered the root cause of the problem, and the pain has eased after receiving treatment..",
            7: "My knee has been hurting for a long time; it hurts whether I walk or sit. I went to the hospital for an examination, and the doctor recommended for surgery, but I didn't want to go through with it. Later, a friend recommended that I try treatment here. Now my knee is much better, and I'm very happy.",
            8: "I always thought my face was very square, but after Master Chia adjusted my jawbone, I began to like my face more and more. I highly recommend it！",
            9: "After the treatment, my back became noticeably straighter. When I went to school, both my teachers and classmates asked me, 'Why do you suddenly look taller?' Hearing these comments made me inexplicably happy.",
        },
    },

    product: {
        Concept: {
            Label: "Concept",
            Title: "Principle of Musculoskeletal Alignment",
            Para1: "Musculoskeletal Alignment is a rehabilitative therapy method that focuses on the bones, muscles, joints, and other tissues of the human body. Its core principle involves utilizing specific techniques and skills to reestablish the biomechanical equilibrium of the skeletal system, muscles, and joints, resulting in pain alleviation and enhanced recovery.",
            Para2: "The underlying principle of Musculoskeletal Alignment lies in the interconnectedness of the body's bones, muscles, and joints, which play a vital role in movement and functionality. Imbalances in the biomechanics of these structures can lead to issues such as pain, stiffness, and misalignment, adversely affecting normal bodily movements and functions.",
            Para3: "The techniques and skills employed in Musculoskeletal Alignment take into account various factors, including body posture, movement patterns, physiological conditions, and more. By addressing specific issues and symptoms, tailored adjustment techniques are applied to restore the biomechanical balance of the bones, muscles, and joints. The ultimate goal is to alleviate pain, improve mobility, and facilitate the process of rehabilitation.",
            Para4: "Musculoskeletal Alignment utilizes these techniques and skills to restore the biomechanical balance of the body's bones, muscles, and joints, thereby achieving pain relief and promoting recovery.",
        },

        Theory: {
            Label: "Theory",
            Title: "Musculoskeletal Alignment Theory",
            Para1: "The theory of Musculoskeletal Alignment, specifically, Efeng Spine Healing is a treatment approach for addressing physical discomfort and pain, developed by Master Chia, a physician from Malaysia.",
            Para2: "According to this theory, the fascia, nerve tissues, ligaments, muscles, and bones throughout the body are interconnected. Any issues within these components can have a widespread impact on the body's overall functioning.",
            Para3: "Musculoskeletal Alignment theory employs techniques to correct joints, fascia, and spinal bones in different areas of the body, with the goal of improving bodily functions and relieving pain. This method typically involves the use of spinal instruments or manual techniques to apply gentle pressure or stretching to the patient's body.",
            Para4: "The approach can be utilized for treating various conditions, including cervical spondylosis, lumbar spondylosis, frozen shoulder, arthritis, sciatica, herniated discs, migraines, insomnia, digestive disorders, and more.",
            Para5: "It is important to highlight that Musculoskeletal Alignment should only be performed by trained professionals, such as certified Musculoskeletal Alignment physicians or therapists, to ensure the effectiveness and safety of the treatment.",
        },

        Uniqueness: {
            Label: "Uniqueness",
            Title: "The uniqueness of Musculoskeletal Alignment",
            SubTitle1: "1. Non-surgical treatment",
            SubTitle1_Para:
                "Musculoskeletal Alignment utilizes spinal instruments or manual techniques for treatment, eliminating the need for surgical intervention and avoiding the risks and painful recovery period associated with surgery.",
            SubTitle2: "2. Personalized treatment",
            SubTitle2_Para:
                "Musculoskeletal Alignment focuses on individualized treatment because each person has unique body structure and issues. Treatment methods need to be tailored accordingly, allowing for personalized and targeted adjustments based on the specific condition of each patient. This personalized approach increases the effectiveness of the treatment.",
            SubTitle3: "3. No side effects",
            SubTitle3_Para:
                "Compared to other forms of treatment such as medication or surgical interventions, the methods used in the theory of Musculoskeletal Alignment are gentle and do not have any side effects. They do not negatively impact the patient's body and are considered safe.",
            SubTitle4: "4. Quick results",
            SubTitle4_Para:
                "Under the theory of Musculoskeletal Alignment, certain conditions can experience rapid relief of symptoms. For example, issues like muscle pain, joint stiffness, and other related symptoms can be alleviated quickly through this form of treatment.",
            SubTitle5: "5. Wide range of applications",
            SubTitle5_Para:
                "Musculoskeletal Alignment is applicable to various conditions, including cervical spondylosis, lumbar spondylosis, frozen shoulder, arthritis, headaches, insomnia, digestive disorders, and more. Therefore, it can be applied in multiple fields to meet the diverse treatment needs of patients.",
        },

        cases: {
            Title: "Cases",
            1: {
                Title: "Scoliosis",
                Content1:
                    "Patient: The doctor said according to the X-ray report that I have scoliosis, and the spine requires a surgical cost of MYR 75,500. At that time, the thought of the doctor using a knife to cut me open made me very scared!",
                Content2:
                    "Later, through a relative's recommendation, I learned about Yi Feng Musculoskeletal Alignment. After undergoing five treatments for scoliosis, my spine has significantly straightened and improved in appearance. I am extremely grateful for Musculoskeletal Alignment therapy, which allowed me to restore a normal body without the need for surgery.",
            },
            2: {
                Title: "Kyphosis",
                Content:
                    "Mother: 'My son has a severe hunchback, with both shoulders inwardly curved and a hunched posture. I worry about him and wonder if he has any orthopedic condition that may result in a lifelong hunchback. After a diagnosis from the doctor, it was confirmed to be a structural hunchback. After just one treatment, the hunchback has significantly improved and straightened.'",
            },
            3: {
                Title: "Sciatica",
                Content:
                    "Due to sciatica causing weakness in the lower back, I was unable to squat without difficulty. After treatment, I can easily squat down without any issues.",
            },
            4: {
                Title: "Face Re-Sculpture",
                Content1:
                    "Throughout my life, I have always felt that my face was very square-shaped. After the physician made adjustments to my lower forehead bone...",
                Content2: "I have started to like my face more and more. I highly recommend it!",
            },
            5: {
                Title: "Frozen Shoulder",
                Content1: "My hand movements are limited to a certain degree without incurring pain nor could I tie my hair.",
                Content2: "However, said problems have been treated in one session.",
            },
            6: {
                Title: "Knee Arthriti",
                Content:
                    "My knee has been hurting for a long time, causing pain when walking or sitting. I went to the hospital for an examination, and the doctor recommended surgery, but I declined. Later, a friend recommended me to come here for treatment. Now, my knee is better, and I am extremely happy.",
            },
        },
    },

    review: {
        Title: "Efeng Spine Healing",
        1: {
            Text1: "I decided to accept the treatment upon a friend's recommendation. The long-term back pain had been preventing me from getting a good night's sleep. The pain significantly improved after a few treatment sessions. Additionally, I have a noticeable improvement from my pelvic pain caused by uneven pelvic alignment where one leg being shorter than the otherdue to a previous fall. Thank you, Master Chia.",
        },
        2: {
            Text1: "Upon receiving treatment from Master Chia, my pain issues have significantly reduced. Especially the shoulder soreness, which has improved a lot. My cervical spine, which originally required surgery, has also shown improvement. It is no longer tense and tight. I am extremely grateful to Master Chia for helping me restore a normal daily life.",
            Text2: "Due to a previous fall, I had an issue with uneven pelvic alignment, resulting in a leg length discrepancy. Prolonged standing caused pelvic pain. However, after treatment, there has been a noticeable improvement. Thank you, Master Chia.",
        },
        3: {
            Text1: "I have been in pain for a year! The X-ray report showed misalignment in my L1-L5 vertebrae. I have tried receiving pain injections three times and taking pain medication, but none of them provided relief. Through Facebook, I learned about Master Chia and his Musculoskeletal Alignment therapy. After three treatments, my lower back pain, leg pain, and numbness in the toes have significantly improved.",
            Text2: "Now, my lower back is no longer sore, and the numbness in my legs and toes has disappeared. Previously, I would experience soreness and numbness after sitting for just half an hour, but now I can sit for extended periods without any issues. I am extremely grateful to Master Chia for allowing me to regain a normal life free from pain and suffering.",
        },
        4: {
            Text1: "After the physician's treatment, I no longer feel any pulling or tension in my muscles and bones. My family and friends have noticed that my body has become much straighter, and I no longer have a tendency to lean forward. I can also fully extend my legs, whereas before they were restricted. Previously, I had to curl up my body to fall asleep, but now I can comfortably sleep on my back. The physician's treatment techniques are highly recommended, and I am grateful for their care and treatment.",
        },
    },

    contact: {
        Title: "Treatment Process",
        Banner: {
            Title: "CONTACT US",
            Shop: "EFENG SPINE HEALING CENTER",
        },
        Consultation: "Consultation",
        Consultation_Descript: "Click on WhatsApp, and our professional consultant will provide more detailed information.",

        Appointment: "Appointment",
        Appointment_Descript1: "Arranging an appointment with the physician for diagnosis.",
        Appointment_Descript2: "Quick responses.",

        Diagnosis: "Diagnosis",
        Diagnosis_Descript: "To inquire your medical condition, undergo a health examination, or receive a diagnosis of the illness.",

        Explanation: "Briefing",
        Explanation_Descript:
            "To provide detailed explanations to the patient regarding their overall health condition and the process of Musculoskeletal Alignment treatment.",

        Cost: "Cost",
        Cost_Descript:
            "To provide the patient a personalized treatment plan and with a clear understanding of the associated payment fees. Offer fair and transparent pricing information.",

        Treatment: "Treatment",
        Treatment_Descript:
            "Based on the patient's condition and diagnosis results, the physician will customize a personalized treatment plan tailored to their unique body structure and issues. This approach aims to achieve the desired treatment outcomes.",

        Follow_up: "Follow-up",
        Follow_up_Descript:
            "Based on the treatment outcomes and patient response, follow-up observations and treatments are conducted, which may include adjusting the treatment plan, providing rehabilitation guidance and advice, and monitoring the progress of the patient's condition.",
    },

    faq: {
        Title: "Frequently Asked Questions",
        1: {
            Question: "Do I need an appointment for a medical consultation?",
            Ans1: "Yes, you need to make an appointment. Please call or reach out to us on WhatsApp to schedule an appointment. We will assign a consultant who will assist you throughout the appointment process.",
            Ans2: "The assigned consultant will ensure all the necessary examinations and procedures you require are arranged.",
        },
        2: {
            Question: "How long does it take to make an appointment?",
            Ans: "As soon as possible. Your appointment date will depend on the availability of the Efeng Spine physician and your needs.",
        },
        3: {
            Question: "Could I request for a specific physician?",
            Ans: "Yes, you are welcome to request an appointment with a specific physician. Rest assured that the specialist will provide you with authoritative professional expertise for your condition.",
        },
        4: {
            Question: "Do I need to provide my medical records to Efeng Spine Healing?",
            Ans: "Yes, our physicians need a clear understanding of your medical condition. We require your medical records, including the history of treatments received from other physicians and any surgical reports that provide detailed information about medical procedures performed.",
        },
        5: {
            Question: "Who could receive treatment at Efeng Spine Healing Center?",
            Ans: "Adults, seniors, children, and infants. The factors involve your specific conditions or diagnosis, as well as other medical information. Feel free to call or reach out to us on WhatsApp to inquire or schedule a consultation.",
        },
        6: {
            Question: "What is a personalized treatment?",
            Ans: "It is a customized plan based on factors such as the severity of the injury, age, physical condition, and other considerations, a personalized treatment plan will be tailored to suit your specific needs.",
        },
        7: {
            Question: "How much does it costs?",
            Ans: "Due to the variations in each individual's body structure and symptoms, the prices may differ. Efeng Spine adheres to fair pricing standards and provides transparent pricing information, ensuring that patients have a clear understanding of the fees they need to pay before undergoing treatment.",
        },
        8: {
            Question: "Could you provide any discounts?",
            Ans: "Unfortunately, we do not offer any discounts. We understand that planning expenses is crucial for international patients. We are committed to providing you with the most accurate cost estimates to avoid any unexpected expenses.",
        },
        9: {
            Question: "What documents do I need to bring when going to Efeng Spine for a medical consultation?",
            Ans: "Your identification card or passport.",
        },
        10: {
            Question: "What should I pay attention to after treatment?",
            Ans: "Efeng Spine physicians or therapists will provide a set of detailed pre-treatment and post-treatment care instructions to ensure the safety and success of the treatment and to help patients recover as quickly as possible.",
        },
        11: {
            Question: "What are the effects of Efeng Spine Healing treatment?",
            Ans: "By adhering to the physician and therapist's rehabilitation program, Efeng Spine Healing treatment can effectively address injuries, promote healing, and achieve the desired treatment outcomes.",
        },
        12: {
            Question: "How is safety ensured?",
            Ans: "Musculoskeletal Alignment, specifically, Efeng Spine Healing treatment is performed by professional physicians and therapists, ensuring that patients receive professional and safe treatment.",
        },
    },

    footer: {
        About: {
            Title: "About Efeng Spine",
            Text: "Efeng Spine Healing is a center that combines traditional Chinese theory with modern medical technology to tailor treatment plans for each patient. Our goal is to help patients restore their body's normal functions and improve their quality of life through scientific therapy and professional care.",
        },
        More: {
            Title: "Links",
        },
        Branch: { Title: "Branch" },
        Community: "Community",
        QRCode: {
            2: "LITTLE RED BOOK",
        },
        Address: "Location",
    },
}

export default translationEN
