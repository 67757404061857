import { useLoaderData, useNavigate } from "react-router-dom"

import { Button, Image } from "antd"
import { LeftOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

import { getTreatmentEntry } from "./usefulScripts"
import ErrorPage from "./Error-page"

export interface TreatmentEntryType {
    imageUrl: string
    Title1: string
    Intro: string
    Title2: string
    Reasons: string[]
    Title3: string
    Consequences: string[]
}

export async function loader({ params }: { params: any }) {
    const entry = await getTreatmentEntry(params.treatmentId)

    return entry
}
function TreatmentEntry() {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const res = useLoaderData() as TreatmentEntryType

    if (res === null) return <ErrorPage />
    else {
        const { imageUrl, Title1, Intro, Title2, Reasons, Title3, Consequences } = res
        // console.log("data:", { imageUrl, Title1, Intro, Title2, Reasons, Title3, Consequences })
        // console.log("img", images["Treatment_1.png"])

        return (
            <div className="space-y-5 lg:space-y-8 pb-12">
                <div className="relative container lg:mx-auto text-center py-5 lg:py-16" style={{ border: "0px solid red" }}>
                    <Button icon={<LeftOutlined />} className="flex justify-self-start" onClick={() => navigate("/treatments")}>
                        {t(`nav.Treatment`)}
                    </Button>
                    <div
                        className="block lg:flex lg:items-start lg:justify-content-between gap-8 mt-3 w-[100%] rounded-lg shadow-md border p-5"
                        style={{ border: "0px solid red" }}
                    >
                        <div
                            className="container self-center w-full md:w-[70%] lg:w-2/4 xl:w-2/4
                        "
                            // style={{ border: "1px solid red" }}
                        >
                            <Image
                                src={imageUrl}
                                className="animate-fade"
                                preview={false}
                                // style={{ border: "0px solid red" }}
                            />
                        </div>
                        <div
                            className="w-full lg:w-2/4 xl:w-2/4 space-y-10 text-left
                        border-t lg:border-t-0 border-s-0 lg:border-s 
                        pt-5 lg:pt-0 ps-0 lg:ps-5
                        "
                            // style={{ border: "0px solid red" }}
                        >
                            <div className="space-y-3 lg:space-y-8">
                                <div className="text-xl lg:text-2xl font-extrabold">{t(Title1)}</div>
                                <div className="text-justify mt-3">{t(Intro)}</div>
                                <div className="space-y-8">
                                    <div className="space-y-3">
                                        <div className="font-bold">{t(Title2)} </div>
                                        <section className="list-decimal space-y-2">
                                            {Reasons.map((reason, i) => {
                                                return t(reason) ? (
                                                    <li key={`reason_${i}`} className="text-normal text-justify">
                                                        {t(reason)}
                                                    </li>
                                                ) : undefined
                                            })}
                                        </section>
                                    </div>
                                    <div className="space-y-3">
                                        <div className="font-bold">{t(Title3)}</div>
                                        <section className="list-decimal space-y-2">
                                            {Consequences.map((consequent, i) => {
                                                return t(consequent) ? (
                                                    <li key={`consequent_${i}`} className="text-normal text-justify">
                                                        {t(consequent)}
                                                    </li>
                                                ) : undefined
                                            })}
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default TreatmentEntry
