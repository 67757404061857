import "bootstrap/dist/css/bootstrap.min.css"
import "./index.css"
import { useTranslation } from "react-i18next"

import MyFooter from "./Footer"
import React from "react"

import { Layout, Menu, Image, theme, Affix, Button, Switch, Grid, Drawer } from "antd"
import { capitalizeFirstLetter, getBreakPoint } from "./usefulScripts"
import type { SubMenuProps } from "rc-menu"
import type { SeedToken } from "antd/es/theme/internal"
import type { MapToken } from "antd/es/theme/interface"
import { Outlet, useNavigate, useLocation } from "react-router-dom"
import { DotsMenuIcon, WhatsAppCustomIcon } from "./CustomIcon"
import type { DrawerStyles } from "antd/es/drawer/DrawerPanel"

// Destructuring
const { useBreakpoint } = Grid
const { Header, Content, Footer } = Layout

const logo = require("./img/logo.jpeg")

// function reveal() {
//     var reveals = document.querySelectorAll(".reveal")
//     for (var i = 0; i < reveals.length; i++) {
//         var windowHeight = window.innerHeight
//         var elementTop = reveals[i].getBoundingClientRect().top

//         var elementVisible = 100
//         //console.log('windowHeight:', windowHeight);
//         //console.log('elementTop:', elementTop);
//         if (elementTop < windowHeight - elementVisible) {
//             //var elementBottom = reveals[i].getBoundingClientRect().bottom;
//             //console.log('windowHeight:', windowHeight, 'elementTop:', elementTop, elementBottom);
//             reveals[i].classList.add("active")
//             /*
//             if (elementBottom <= 0) {
//                 reveals[i].classList.remove("active");
//             } else {
//                 reveals[i].classList.add("active");
//             }*/
//         } else {
//             reveals[i].classList.remove("active")
//         }
//     }
// }

function Root({
    currentTheme,
    changeTheme,
    configCSSManually,
    lang,
    setLang,
}: {
    currentTheme: { algorithm: (token: SeedToken) => MapToken }
    changeTheme: (checked: boolean) => void
    configCSSManually: () => void
    lang: string
    setLang: React.Dispatch<React.SetStateAction<string>>
}) {
    const { token } = theme.useToken()
    const { t, i18n } = useTranslation()

    const navigate = useNavigate()
    let location = useLocation()
    const screens = useBreakpoint()
    const [breakPoint, setBreakPoint] = React.useState(() => getBreakPoint(screens))

    React.useEffect(() => {
        setBreakPoint(() => getBreakPoint(screens))
    }, [screens])

    // React.useEffect(() => {
    //     // console.log("menu:", breakPoint, menuWidth[breakPoint])
    // }, [breakPoint])

    const handleLangChange = () => {
        if (lang === "en") {
            i18n.changeLanguage("zh")

            setLang("zh")
        } else {
            i18n.changeLanguage("en")
            setLang("en")
        }
    }
    const [current, setCurrent] = React.useState("1")

    // ----------Affix States----------
    // const [bottom, setBottom] = React.useState<number>(100)
    const [affixAnim, setAffixAnim] = React.useState<string | null>(null)

    const affixOnClick = () => {
        // Affix Animation
        if (affixAnim === null) {
            setAffixAnim("scaleUp")
        } else {
            setAffixAnim(null)
        }
        // Open whatsApp
        setTimeout(function () {
            // const ele: HTMLElement | null = document.querySelector(".whatsAppURL")
            // if (!ele) throw new Error(`Missing element: ${JSON.stringify(ele, null, "  ")}`)
            // ele.click()
            window.open("https://api.whatsapp.com/send?phone=60133237828&text=Hi,%20I%20would%20like%20to%20know%20more!", "_blank")
            setAffixAnim(null)
        }, 500)
    }

    // ----------Menu States----------
    const [menu, setMenu] = React.useState("1")
    const menuWidth: { [key: string]: number } = {
        xs: 50,
        sm: 50,
        md: 450,
        lg: 500,
        xl: 850,
        xxl: 850,
    }

    React.useEffect(() => {
        const PathToMenuIDMap: { [key: string]: string } = {
            "/": "1",
            "/about": "2",
            "/treatments": "3",
            "/experience": "4",
            "/contact": "6",
        }
        // console.log(window.location.pathname)
        setCurrent(PathToMenuIDMap[window.location.pathname])
        // let section: NodeListOf<HTMLElement> = document.querySelectorAll(".mySection")
        // let lists = document.querySelectorAll(".list")
        // function activeLink(li: HTMLElement) {
        //     lists.forEach((item) => {
        //         item.classList.remove("ant-menu-item-active")
        //         item.classList.remove("ant-menu-item-selected")
        //     })
        //     li.classList.add("ant-menu-item-active")
        //     li.classList.add("ant-menu-item-selected")
        // }
        // window.onscroll = () => {
        //     section.forEach((sec) => {
        //         let top = window.scrollY
        //         let offset = sec.offsetTop
        //         let height = sec.offsetHeight
        //         let id = sec.getAttribute("id")
        //         if (top >= offset && top < offset + height) {
        //             //const target = document.querySelector(`[href='#${id}']`).parentElement;
        //             const target: HTMLElement | null = document.querySelector(`.nav_${id}`)
        //             if (target) activeLink(target)
        //         }
        //     })
        // }
        // let menuIcon: HTMLElement | null = document.querySelector(".anticon-ellipsis")
        // if (!menuIcon) throw new Error(`Missing menuIcon: ${JSON.stringify(menuIcon, null, "  ")}`)
        // // Replace menuIcon svg to <MenuOutlined />
        // menuIcon.children[0].children[0].setAttribute(
        //     "d",
        //     "M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"
        // )
        // window.addEventListener("scroll", reveal, { passive: true })
        // reveal()
        // return () => window.removeEventListener("scroll", reveal)
    }, [location.pathname])

    const [openDrawer, setOpenDrawer] = React.useState<boolean>(false)
    const SiderItems = [
        {
            key: String(1),
            // icon: React.createElement(HomeOutlined),
            label: capitalizeFirstLetter(t("nav.Home")),
            className: `list nav_home ${openDrawer ? "mb-3" : lang === "zh" ? "me-3 px-4" : ""}`,
        },
        {
            key: String(2),
            // icon: React.createElement(ReadOutlined),
            label: capitalizeFirstLetter(t("nav.About")),
            className: `list nav_about ${openDrawer ? "mb-3" : lang === "zh" ? "me-3 px-4" : ""}`,
        },
        {
            key: String(3),
            // icon: React.createElement(ShopOutlined),
            label: capitalizeFirstLetter(t("nav.Treatment")),
            className: `list nav_treatment ${openDrawer ? "mb-3" : lang === "zh" ? "me-3 px-4" : ""}`,
        },
        {
            key: String(4),
            // icon: React.createElement(FormOutlined),
            label: capitalizeFirstLetter(t("nav.Experience")),
            className: `list nav_experience ${openDrawer ? "mb-3" : lang === "zh" ? "me-3 px-4" : ""}`,
        },
        {
            key: String(5),
            // icon: React.createElement(QuestionCircleOutlined),
            label: capitalizeFirstLetter(t("nav.Contact")),
            className: `list nav_contact ${openDrawer ? "mb-3" : lang === "zh" ? "me-3 px-4" : ""}`,
        },
    ]
    const drawerStyles: DrawerStyles = {
        mask: {
            backdropFilter: "blur(1px)",
        },
        content: {
            backgroundColor: currentTheme.algorithm === theme.darkAlgorithm ? token.colorBgSpotlight : token.colorBgBase,
            boxShadow: `-2px 0 20px ${token.colorBgMask}`,
            opacity: 0.9,
        },
        header: {
            borderBottom: `0px solid ${token.colorPrimary}`,
        },
        body: {
            fontSize: token.fontSizeLG,
        },
        footer: {
            borderTop: `1px solid ${token.colorBorder}`,
        },
    }
    const scrollToTop = () => {
        let ele: HTMLElement | null = null
        ele = document.getElementById("layout")
        if (!ele) throw new Error(`scrollToTop: Missing element 'layout'`)
        ele.scrollIntoView({ behavior: "smooth" })
    }
    const menuOnSelect: SubMenuProps["onTitleClick"] = (values) => {
        setMenu(values.key)
    }
    const menuOnClick: SubMenuProps["onTitleClick"] = (e) => {
        setCurrent(e.key)

        switch (e.key) {
            case "1":
                navigate("/")
                scrollToTop()
                break
            case "2":
                navigate("/about")
                scrollToTop()
                break
            case "3":
                navigate("/treatments")
                scrollToTop()
                break
            case "4":
                navigate("/experience")
                scrollToTop()
                break
            case "5":
                navigate("/contact")
                scrollToTop()
                break
            default:
                scrollToTop()
        }
    }

    const affixCondiiton = breakPoint === "md" || breakPoint === "sm" || breakPoint === "xs"
    return (
        <Layout id="layout" className="layout shadow">
            <Header
                className="z-50"
                style={{
                    background: token.colorBgContainer,
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    width: "100%",
                    height: "auto",
                    paddingInline: 0,
                    boxShadow: "0px 8px 15px -15px grey",
                    // borderBottom: "0.5px solid grey",
                }}
            >
                <div className="">
                    <div className="row">
                        <div className="px-0 mx-0">
                            <div
                                className="d-flex flex-row justify-content-between align-items-center my-3 mx-12"
                                // style={{ border: "1px dotted green" }}
                            >
                                <div className="d-flex flex-row justify-content-around align-items-center max-w-[11rem] lg:max-w-[15rem] ">
                                    <Image
                                        // className="hover:scale-110"
                                        preview={false}
                                        src={logo}
                                        // height={"7.5vh"}
                                        // width={"20vw"}
                                        onClick={() => {
                                            navigate("/")
                                            scrollToTop()
                                        }}
                                    />
                                </div>
                                {/* <div
                                    className="logo d-flex flex-row justify-content-around align-items-center flex-wrap text-nowrap my-1 ps-3"
                                    style={{ fontSize: "1.1rem", lineHeight: "34px" }}
                                >
                                    <div className="HeaderTitle gold">
                                        <span style={{ fontSize: "27px", letterSpacing: 1 }}>E</span>FENG{" "}
                                        <span style={{ fontSize: "27px", letterSpacing: 1 }}>S</span>PINE{" "}
                                        <span style={{ fontSize: "27px", letterSpacing: 1 }}>H</span>EALING{" "}
                                        <span style={{ fontSize: "27px", letterSpacing: 1 }}>C</span>ENTER
                                    </div>
                                    <div className="HeaderTitle gold fw-bold ms-3" style={{ fontSize: "1.25rem", letterSpacing: 3 }}>
                                        易峰筋骨还原
                                    </div>
                                </div> */}
                                <div
                                    className={`${
                                        breakPoint !== `xl` && breakPoint !== `xxl`
                                            ? "hidden"
                                            : "d-flex flex-row justify-content-end align-items-center"
                                    }`}
                                >
                                    <Menu
                                        className="d-flex flex-row justify-content-end me-3"
                                        // className="grid grid-cols-12 me-3"
                                        theme={currentTheme.algorithm === theme.darkAlgorithm ? "dark" : "light"}
                                        mode="horizontal"
                                        defaultSelectedKeys={["1"]}
                                        selectedKeys={[current]}
                                        items={SiderItems}
                                        onSelect={menuOnSelect}
                                        onClick={menuOnClick}
                                        disabledOverflow={true}
                                        style={{
                                            width: menuWidth[breakPoint],
                                            fontSize: "16px",
                                            borderBottom: "0px",
                                            fontFamily: "Exo2.0-Regular",
                                            fontWeight: 600,
                                            // border: "1px dotted red",
                                        }}
                                    />
                                    <div className="mb-1">
                                        <Switch
                                            checkedChildren="EN"
                                            unCheckedChildren="CH"
                                            checked={lang === "en" ? true : false}
                                            onClick={handleLangChange}
                                        />
                                    </div>
                                </div>
                                <div className={`${breakPoint === `xl` || breakPoint === `xxl` ? "hidden" : "d-flex flex-row align-items-center"}`}>
                                    <Button
                                        icon={<DotsMenuIcon style={{ fontSize: "2rem", color: token.colorText }} />}
                                        type="text"
                                        className="hover:scale-125 z-10 py-5"
                                        onClick={() => {
                                            setOpenDrawer(true)
                                            // console.log("drawer")
                                        }}
                                        // style={{ fill: "black", backgroundColor: "white" }}
                                    />
                                    <Drawer
                                        title={
                                            <>
                                                <Switch
                                                    checkedChildren="EN"
                                                    unCheckedChildren="CH"
                                                    checked={lang === "en" ? true : false}
                                                    onClick={handleLangChange}
                                                    className="ms-4"
                                                    // style={{ height: "2 rem" , width: "auto"}}
                                                />
                                                {/* <Switch
                                                    checkedChildren={<MoonOutlined />}
                                                    unCheckedChildren={<SunOutlined />}
                                                    checked={currentTheme.algorithm === theme.darkAlgorithm ? true : false}
                                                    onClick={changeTheme}
                                                    className="ms-4"
                                                    // style={{ height: "2 rem" , width: "auto"}}
                                                /> */}
                                            </>
                                        }
                                        onClose={() => setOpenDrawer(false)}
                                        open={openDrawer}
                                        styles={drawerStyles}
                                    >
                                        <Menu
                                            // className="d-flex flex-row justify-content-end pe-4"
                                            theme={currentTheme.algorithm === theme.darkAlgorithm ? "dark" : "light"}
                                            mode="vertical"
                                            defaultSelectedKeys={["1"]}
                                            selectedKeys={[current]}
                                            items={SiderItems}
                                            onSelect={menuOnSelect}
                                            onClick={(info) => {
                                                menuOnClick(info)
                                                setOpenDrawer(false)
                                            }}
                                            disabledOverflow={true}
                                            style={{
                                                fontSize: "17px",
                                                borderRight: "0px",
                                                fontFamily: "Exo2.0-Regular",
                                                fontWeight: 600,
                                            }}
                                        />
                                    </Drawer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Header>

            <Layout>
                <Content className="" style={{ padding: "1px 0px" }}>
                    <div style={{ background: token.colorBgContainer }}>
                        <Outlet context={{ lang }} />
                    </div>
                </Content>
                <Footer>
                    <MyFooter />
                </Footer>
                <Affix>
                    <div
                        className={`fixed bottom-[5.5rem] 2xs:bottom-[5.5rem] xs:bottom-[5.5rem] sm:bottom-[5.5rem] md:bottom-[5rem] lg:bottom-[6rem]
                            right-[5rem] xs:right-[5rem] md:right-[5rem] lg:right-[6rem] ${affixAnim}`}
                    >
                        <Button
                            icon={<WhatsAppCustomIcon className="" style={{ fontSize: affixCondiiton ? 30 : 45 }} />}
                            type="link"
                            className="fixed 
                            rounded-full fill-white bg-green-500 bg-opacity-100 z-50 hover:bg-green-600 
                            hover:motion-safe:scale-110 cursor-pointer 
                            "
                            style={{ padding: affixCondiiton ? "1.8rem" : "2.3rem", border: "0px solid red", backgroundColor: "#22c55e" }}
                            onClick={affixOnClick}
                        />
                        <Button
                            icon={<WhatsAppCustomIcon className="" style={{ fontSize: affixCondiiton ? 30 : 45, opacity: 0 }} />}
                            type="link"
                            className="fixed 
                            rounded-full fill-white bg-green-500 bg-opacity-100 -z-1
                            hover:motion-safe:scale-110 cursor-pointer 
                            "
                            style={{
                                padding: affixCondiiton ? "1.8rem" : "2.3rem",
                                animation: "pulsate 1.5s ease-out",
                                animationIterationCount: "infinite",
                                border: "0px solid red",
                            }}
                        />
                    </div>
                </Affix>
            </Layout>
        </Layout>
    )
}

export default Root
